// ServiceRequestDetailsModal.jsx
import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  useToast,
} from "@chakra-ui/react";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "config/firebase";

const ServiceRequestDetailsModal = ({ isOpen, onClose, details }) => {
  const toast = useToast()
  if (!details) {
    return null;
  }

  const handleNotifyPartner = async () => {
    try {
      const serviceRequestRef = doc(db, 'quotations', details?.original?.id);
      const partnerCollection = collection(db, 'partners');
      const partners = await getDocs(query(partnerCollection, where('industry', '==', details?.original?.projectType?.trim())));
      const partnerIds = partners.docs.map((doc) => doc.id);
      await updateDoc(serviceRequestRef, { status: "Notified", notifiedPartners: partnerIds });
      onClose()
      toast({
        description: "Partner Notified Successfully",
        status: "success",
        position: 'top',
        duration: 1000,
        isClosable: true,
      });


    } catch (error) {
      console.error('Error updating document: ', error.message);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Service Request Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "20px", width: "50%" }}
              >
                <Text style={{ fontWeight: "bold" }}>
                  Industry
                </Text>
                <Text style={{ fontWeight: "bold" }}>
                  Timeline
                </Text>
                <Text style={{ fontWeight: "bold" }}>Budget</Text>
                <Text style={{ fontWeight: "bold" }}>
                  Project Requirements
                </Text>

              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "20px", width: "50%" }}
              >
                <Text>{details?.original.projectType}</Text>
                <Text>{details?.original.timeLine}</Text>
                <Text>{details?.original.budget}</Text>
                <Text>
                  {details?.original.requirements}
                </Text>
              </div>
            </div>
            <div style={{ paddingTop: "20px" }}>
              <Button colorScheme="blue" mt={4} onClick={handleNotifyPartner} disabled={details?.original?.status=="Notified"}>
               {details?.original?.status=="Notified"?"Notified":"Notify Partner"}
              </Button>
              <Button colorScheme="green" mt={4} ml={4}>
                Update Client
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ServiceRequestDetailsModal;
