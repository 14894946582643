import React, { useState, useEffect } from "react";
import { db } from "config/firebase";
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import ServiceRequestDetailsModal from "./ServiceRequestDetailsModal";
import ComplexTable from "../components/ComplexTable";
import { Box, Flex, Spinner } from "@chakra-ui/react";


const ServiceRequests = ({ serviceRequests: propServiceRequests }) => {
  const [serviceRequests, setServiceRequests] = useState([]);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [unSubscribe,setUnSubscribe]=useState(null)
  const handleViewDetails = (details) => {
    setSelectedDetails(details);
    setIsDetailsModalOpen(true);
  };


  useEffect(() => {
    getServiceRequests();
    return () => {
      if (unSubscribe) {
        unSubscribe();
      }
    };
  }, []);

  const getServiceRequests = async () => {
    try {
      const subScribe= onSnapshot(collection(db, "quotations"), (snapshot) => {
        const serviceRequests = snapshot.docs.map((doc, index) => {
          return({
            ...doc.data(),
            srNo:index+1,
            id: doc.id,
            email:doc.data().userDetails?.email
          })
        });
        setServiceRequests(serviceRequests);
        setLoading(false);
      });
      setUnSubscribe(()=>subScribe)
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  }


  const columns = [
    {
      Header: "SRNO",
      accessor: "srNo",
    },
    {
      Header: "USER",
      accessor: "email",
    },
    {
      Header: "PROJECT TYPE",
      accessor: "projectType",
    },
    {
      Header: "REQUIREMENTS",
      accessor: "requirements",
    },
    {
      Header: "TIMELINE",
      accessor: "timeLine",
    },
    {
      Header: "BUDGET",
      accessor: "budget",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "ACTION",
      accessor: "action",
    },
  ];

  return (
    <Box pt={{ base: "180px", md: "5px", xl: "5px" }}>
      <Flex align="center" justify="center" h="100%">
        {loading ? (
          <Spinner size="xl" marginTop={'5rem'} />
        ) : (
          <ComplexTable columnsData={columns} tableData={serviceRequests} />
        )}
      </Flex>
    </Box>
  );
};

export default ServiceRequests;
